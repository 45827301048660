<template>
    <div class="w-full">
      <Loader v-if="loading" class="w-full"/>
      <div v-else class="w-full grid grid-cols-2 gap-y-6 gap-x-6 font-mont bg-white shadow-lg
        border border-grayedHR border-b-0 rounded rounded-b-none"
      >
        <div class="col-span-2">
          <!-- Post picture -->
          <PostCard v-if="postLoaded" :post-data="postData" single-post />
          <div class="mt-3 mx-6 text-sm flex flex-row text-grayed justify-end"
               :class="[]"
               v-if="postData"
          >
            <span class="hidden lg:block text-black text-s">{{ postData.authorName }}</span>
            <span class="hidden lg:block">&nbsp;|&nbsp;</span><span class="text-redAccent text-s"
                 :class="{'hover:opacity-50': !isCurrentRoute(postData.categorySlug)}"
                 @click.stop="!isCurrentRoute(postData.categorySlug) ? goToCategory(postData.categorySlug) : null"
              >
              {{ trans(`POST_CATEGORY:${postData.categorySlug}`) }}
            </span>
            &nbsp;|&nbsp;<span class="text-black text-s">{{ postData.publishedAt }}</span>
          </div>
          <!-- Post excerpt and text -->
          <div v-if="postLoaded" class="bg-white text-base pt-4 px-3 lg:px-6 pb-3 lg:pb-6 rounded-b-md space-y-4">
            <div class="font-semibold leading-snug text-base lg:text-lg text-black">
              <span v-html="postData.excerpt"></span>
            </div>
            <hr class="border-transparent"/>
            <div class="text-base post-content">
              <span class="post-body" v-html="postData.body"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Contact form -->
      <template v-if="$route.params.category === 'contact' && !loading">
        <t-card variant="contact">
          <hr class="-mx-6 -mt-3 border-grayedDark border-dashed bg-white"/>
          <ContactForm :type="'CONTACT_FORM'" />
        </t-card>
      </template>
    </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import Button from "@/utils/crud/components/Button";
import Comments from "@/views/content/components/Comments";
import CommentForm from "@/views/content/components/CommentForm";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import Loader from "@/views/Loader";
import router from "@/router";
import PostCard from "@/views/content/posts/components/PostCard";
import ContactForm from "@/views/content/components/ContactForm";

export default {
  name: "PostShowView",
  components: {ContactForm, PostCard, Loader, PageTitle, PageContainer, Comments, Button, CommentForm},
  watch: {
    '$route.params.slug': {
      handler: function(val) {
        if(val) {
          this.fetchPostShowRequest({slug: this.$route.params.slug});
        }
      },
      deep: true,
      immediate: true,
    },
    langLocale() {
      this.fetchPostShowRequest({slug: this.$route.params.slug});
    },
  },
  computed: {
    ...mapGetters('posts', ['postData', 'postLoaded']),
    ...mapGetters('loader', ['loading']),
    ...mapGetters('account', ['userData'])
  },
  methods: {
    ...mapActions('posts', ['fetchPostShowRequest']),
	  ...mapMutations('posts', ['setPostData']),

    goToCategory(slug) {
      router.push({name: 'posts', params: {category: slug}});
    },
    goToPost(categorySlug, postSlug) {
      router.push({name: 'post-show', params: {category: categorySlug, slug: postSlug}})
    },
    isCurrentRoute(slug) {
      return this.$route.params.category === slug;
    },
  },
	destroyed() {
		this.setPostData(null);
	}
}
</script>

<style lang="scss">
.h-post-screen {
  height: 78vh;
}
@media (min-width: 1024px) {
  .h-post-screen {
    height: 88vh;
  }
}

.post-content {
	h1 {
		font-size: theme('fontSize.xl');
    line-height: 1.25;
	}

	h2 {
		font-size: theme('fontSize.xl');
    line-height: 1.25;
	}

  a {
    text-decoration: underline;
  }
  a:hover {
    color: #800000;
  }
}
</style>
