<template>
  <div class="relative font-mont group lg:rounded-t-md"
    :class="{
      'cursor-pointer lg:rounded-b-md': !singlePost,
      'h-first-post-img max-h-first-post-img': !small,
      'h-post-img max-h-post-img':small,
    }"
       @click="!singlePost ? goToPost(postData.categorySlug, postData.slug) : null"
       :style="[{background: 'url(' + postData.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]">
    <div v-if="!emptyPost" class="flex flex-col justify-end h-full lg:rounded-t-md"
      :class="[{'lg:rounded-b-md': !singlePost}]"
    >
      <div class="p-3 lg:p-6">
        <div class="flex flex-col px-3 lg:px-4 py-3 bg-white w-full border border-grayedHRDark opacity-95">
          <div class="space-y-3">
            <div class="text-black font-semibold leading-tight"
              :class="[transformScale, {'text-base':small, 'text-lg':!small}]"
            >
              {{ small?excerpt(postData.title,85):postData.title }}
            </div>
            <div v-if="!small" class="text-grayedDark leading-snug "
              :class="[transformScale,{'hidden': singlePost,'text-sm':small,'text-base':!small }]"
            >
              <span v-html="postData.excerpt">
              </span>
            </div>
          </div>
          <hr class="border-grayedDark border-dashed -mx-3 lg:-mx-4" :class="[{
            'hidden': singlePost,
            'my-3':!small,
            'my-2':small,
          }]"/>
          <div class="text-sm flex flex-row text-grayed justify-end"
            :class="[transformScale, {
              'hidden': singlePost,
              'text-s':!small,
              'text-xs':small,
            }]"
            v-if="!emptyPost"
          >
            <span v-if="!narrow && !small" class="text-black hidden lg:block">{{ postData.authorName }}</span>
            <span v-if="!narrow && !small" class="hidden lg:block">&nbsp;|&nbsp;</span><span class="text-redAccent"
                  :class="{'hover:opacity-50': !isCurrentRoute(postData.categorySlug)}"
                  @click.stop="!isCurrentRoute(postData.categorySlug) ? goToCategory(postData.categorySlug) : null">
              {{ trans(`POST_CATEGORY:${postData.categorySlug}`) }}
            </span>
              &nbsp;|&nbsp;<span class="text-black">{{ postData.publishedAt }}</span>
          </div>
        </div>
      </div>
      <hr class="border-solid border-grayedHR" :class="[{'hidden': !singlePost}]"/>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {excerpt} from "@/common/helpers/utils";

export default {
  name: "PostCard",
  props: {
    postData: Object,
    narrow: Boolean,
    small: Boolean,
    singlePost: Boolean,
	  emptyPost: Boolean,
  },
  data() {
    return {
      excerpt:excerpt,
      transformScale: !this.singlePost ? 'transform ease-out duration-300 lg:group-hover:scale-101' : '',
    }
  },
  methods: {
    goToCategory(slug) {
      router.push({name: 'posts', params: {category: slug}});
    },
    goToPost(categorySlug, postSlug) {
      router.push({name: 'post-show', params: {category: categorySlug, slug: postSlug}})
    },
    isCurrentRoute(slug) {
      return this.$route.params.category === slug;
    },
  }
}
</script>

<style scoped>
  .bg-first-post {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 54%);
  }
</style>
