var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative font-mont group lg:rounded-t-md",class:{
    'cursor-pointer lg:rounded-b-md': !_vm.singlePost,
    'h-first-post-img max-h-first-post-img': !_vm.small,
    'h-post-img max-h-post-img':_vm.small,
  },style:([{background: 'url(' + _vm.postData.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}]),on:{"click":function($event){!_vm.singlePost ? _vm.goToPost(_vm.postData.categorySlug, _vm.postData.slug) : null}}},[(!_vm.emptyPost)?_c('div',{staticClass:"flex flex-col justify-end h-full lg:rounded-t-md",class:[{'lg:rounded-b-md': !_vm.singlePost}]},[_c('div',{staticClass:"p-3 lg:p-6"},[_c('div',{staticClass:"flex flex-col px-3 lg:px-4 py-3 bg-white w-full border border-grayedHRDark opacity-95"},[_c('div',{staticClass:"space-y-3"},[_c('div',{staticClass:"text-black font-semibold leading-tight",class:[_vm.transformScale, {'text-base':_vm.small, 'text-lg':!_vm.small}]},[_vm._v(" "+_vm._s(_vm.small?_vm.excerpt(_vm.postData.title,85):_vm.postData.title)+" ")]),(!_vm.small)?_c('div',{staticClass:"text-grayedDark leading-snug ",class:[_vm.transformScale,{'hidden': _vm.singlePost,'text-sm':_vm.small,'text-base':!_vm.small }]},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.postData.excerpt)}})]):_vm._e()]),_c('hr',{staticClass:"border-grayedDark border-dashed -mx-3 lg:-mx-4",class:[{
          'hidden': _vm.singlePost,
          'my-3':!_vm.small,
          'my-2':_vm.small,
        }]}),(!_vm.emptyPost)?_c('div',{staticClass:"text-sm flex flex-row text-grayed justify-end",class:[_vm.transformScale, {
            'hidden': _vm.singlePost,
            'text-s':!_vm.small,
            'text-xs':_vm.small,
          }]},[(!_vm.narrow && !_vm.small)?_c('span',{staticClass:"text-black hidden lg:block"},[_vm._v(_vm._s(_vm.postData.authorName))]):_vm._e(),(!_vm.narrow && !_vm.small)?_c('span',{staticClass:"hidden lg:block"},[_vm._v(" | ")]):_vm._e(),_c('span',{staticClass:"text-redAccent",class:{'hover:opacity-50': !_vm.isCurrentRoute(_vm.postData.categorySlug)},on:{"click":function($event){$event.stopPropagation();!_vm.isCurrentRoute(_vm.postData.categorySlug) ? _vm.goToCategory(_vm.postData.categorySlug) : null}}},[_vm._v(" "+_vm._s(_vm.trans(("POST_CATEGORY:" + (_vm.postData.categorySlug))))+" ")]),_vm._v("  | "),_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.postData.publishedAt))])]):_vm._e()])]),_c('hr',{staticClass:"border-solid border-grayedHR",class:[{'hidden': !_vm.singlePost}]})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }